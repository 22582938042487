// @ts-check
import { forwardRef } from 'react';
import { TextField } from '@mui/material';

/**
 * @typedef {import('@mui/material/TextField').TextFieldProps} TextFieldProps
 */

/**
 * Base text input component
 * @param {TextFieldProps} props
 * @return {React.JSX.Element}
 */
export const TextInput = forwardRef(
  (/** @type {TextFieldProps} */ props, ref) => {
    return <TextField {...props} ref={ref} />;
  },
);
