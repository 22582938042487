import { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { InputAdornment, Stack, Divider, useTheme } from '@mui/material';
import { useReactiveVar } from '@apollo/client';
import { Button } from '../../../newComponents/Button';
import { IconButton } from '../../../newComponents/IconButton';
import { Typography } from '../../../newComponents/Typography';
import { TextInput } from '../../../newComponents/TextInput';
import { CircularProgress } from '../../../newComponents/Progress';
import { Upload } from '../../../newComponents/Upload';
import type { OnChange as OnUploadChange } from '../../../newComponents/Upload/FileUpload.types';
import { mimeTypes } from '../../../utils/constants';
import { useSignDocumentsWithEfirma } from './DocumentSignDialogHooks';
import { globalSnackbarVar } from '../../../cache.reactiveVars';
import { documentSignDialogVar } from './DocumentSignDialog.vars';
import { CertFileIcon } from './DocumentSignDialogAssets/CertFileIcon';
import { KeyFileIcon } from './DocumentSignDialogAssets/KeyFileIcon';

export const DocumentSignWithEfirmaForm = () => {
  const theme = useTheme();
  const { signing } = useReactiveVar(documentSignDialogVar);
  const form = useForm({ mode: 'onChange', defaultValues: { password: '' } });
  const { handleSignDocuments } = useSignDocumentsWithEfirma();
  const [showPassword, setShowPassword] = useState(false);
  const [certFile, setCertFile] = useState<File | null>(null);
  const [keyFile, setKeyFile] = useState<File | null>(null);

  const handleSign = () => {
    handleSignDocuments({
      password: form.getValues('password'),
      privateKey: keyFile,
      publicKey: certFile,
      onIncorrectPassword: () => {
        form.setError('password', {
          type: 'manual',
          message: 'Contraseña incorrecta',
        });
      },
    });
  };

  const onCertFileChange: OnUploadChange = (acceptedFiles, fileRejections) => {
    if (fileRejections.length !== 0) {
      const message = fileRejections[0].errors[0].message;
      globalSnackbarVar({ show: true, message, severity: 'error' });
    } else if (acceptedFiles.length !== 0) {
      setCertFile(acceptedFiles[0]);
    }
  };

  const onKeyFileChange: OnUploadChange = (acceptedFiles, fileRejections) => {
    if (fileRejections.length !== 0) {
      const message = fileRejections[0].errors[0].message;
      globalSnackbarVar({ show: true, message, severity: 'error' });
    } else if (acceptedFiles.length !== 0) {
      setKeyFile(acceptedFiles[0]);
    }
  };

  const isValid =
    form.formState.isValid && certFile !== null && keyFile !== null;

  if (signing) {
    return (
      <Stack
        gap={3}
        minHeight={370}
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress size={100} />
        <Typography variant="h4">Firmando</Typography>
      </Stack>
    );
  }

  return (
    <Stack alignItems="center" gap={2}>
      <Upload
        variant="blockFile"
        accept={mimeTypes.cer}
        sx={{ width: '100%', height: '100%', alignItems: 'left' }}
        content={
          certFile ? (
            <Stack direction="row" alignItems="center" gap={2} p={2}>
              <CertFileIcon color={theme.newPalette.primary.main} />
              <Stack>
                <Typography variant="subtitle2">{certFile.name}</Typography>
              </Stack>
            </Stack>
          ) : (
            <Stack direction="row" alignItems="center" gap={2} p={2}>
              <CertFileIcon color={theme.newPalette.grey.grey500} />
              <Stack>
                <Typography variant="subtitle2">
                  Selecciona el archivo .cer
                </Typography>
                <Typography variant="caption">
                  Arrastra aqui o da clic para subir el archivo
                </Typography>
              </Stack>
            </Stack>
          )
        }
        onChange={onCertFileChange}
      />
      <Upload
        variant="blockFile"
        accept={mimeTypes.key}
        sx={{ width: '100%', height: '100%', alignItems: 'left' }}
        content={
          keyFile ? (
            <Stack direction="row" alignItems="center" gap={2} p={2}>
              <KeyFileIcon color={theme.newPalette.primary.main} />
              <Stack>
                <Typography variant="subtitle2">{keyFile.name}</Typography>
              </Stack>
            </Stack>
          ) : (
            <Stack direction="row" alignItems="center" gap={2} p={2}>
              <KeyFileIcon color={theme.newPalette.grey.grey500} />
              <Stack>
                <Typography variant="subtitle2">
                  Selecciona el archivo .key
                </Typography>
                <Typography variant="caption">
                  Arrastra aqui o da clic para subir el archivo
                </Typography>
              </Stack>
            </Stack>
          )
        }
        onChange={onKeyFileChange}
      />
      <Divider sx={{ width: '100%' }} />
      <Controller
        name="password"
        control={form.control}
        rules={{
          required: 'Ingresa la contraseña de tu e.firma',
          minLength: {
            value: 8,
            message: 'La contraseña debe tener al menos 8 caracteres',
          },
        }}
        render={({ field }) => (
          <TextInput
            value={field.value}
            onChange={field.onChange}
            onBlur={field.onBlur}
            type={showPassword ? 'text' : 'password'}
            placeholder="Contraseña de tu e.firma"
            size="medium"
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    icon={showPassword ? 'eye_line' : 'eye_close_line'}
                    color="default"
                    onClick={() => setShowPassword(!showPassword)}
                  />
                </InputAdornment>
              ),
            }}
            error={!!form.formState.errors.password}
            helperText={form.formState.errors.password?.message}
          />
        )}
      />
      <Button
        variant="contained"
        color="primary"
        fullWidth
        size="large"
        sx={{ mt: 2 }}
        disabled={!isValid}
        onClick={handleSign}
      >
        Firmar
      </Button>
    </Stack>
  );
};
