import { useReactiveVar, useMutation } from '@apollo/client';
import { sifeEndpoints } from '../../../../API/sifeEndpoints';
import { globalSnackbarVar } from '../../../../cache.reactiveVars';
import { SIFE_CONFIRM_DOCUMENT_SIGN } from '../../../../containers/RHPod/EmployeePortal/gql';
import type { HandleSignDocumentsEfirmaInput } from '../DocumentSignDialog.types';
import { signDocumentsWithEfirma } from '../DocumentSingDialogHelpers/signDocumentsWithEfirma.helpers';
import { getVerificationIdFromToken } from '../../IdentityVerificationDialog/IdentityVerificationDialog.helpers';
import { openFullScreenLottieAnimation } from '../../../LottieAnimation/LottieAnimation.vars';
import { useScreenSize } from '../../../../Hooks';
import {
  SIGNATURE_TYPES,
  DESKTOP_ANIMATION,
  MOBILE_ANIMATION,
} from '../DocumentSignDialog.constants';
import {
  documentSignDialogVar,
  setDocumentSignDialogVar,
} from '../DocumentSignDialog.vars';

export const useSignDocumentsWithEfirma = () => {
  const { isMobile } = useScreenSize();
  const [confirmDocumentSign] = useMutation(SIFE_CONFIRM_DOCUMENT_SIGN);
  const { documents, sifeDocuments, onSign } = useReactiveVar(
    documentSignDialogVar,
  );

  const handleSignDocuments = async (input: HandleSignDocumentsEfirmaInput) => {
    const filteresPendingDocs = sifeDocuments.filter((doc) => {
      if (doc.videoAgrmtReqAtDocSigning?.required) return false;
      if (doc.signatureType !== SIGNATURE_TYPES.SAT_ELECTRONIC_SIGNATURE) {
        return false;
      }
      return true;
    });

    if (filteresPendingDocs.length === 0) {
      globalSnackbarVar({
        show: true,
        message: 'No hay documentos para firmar',
        severity: 'info',
      });
      return;
    }

    const { password, privateKey, publicKey, onIncorrectPassword } = input;

    try {
      setDocumentSignDialogVar({ signing: true });

      // check if some document needs verification at sign and get verificationId from token
      let verificationId = '';
      const someDocumentNeedsVerificationAtSign = sifeDocuments.some(
        ({ verifReqAtDocSigning }) => verifReqAtDocSigning?.required,
      );

      if (someDocumentNeedsVerificationAtSign) {
        verificationId = getVerificationIdFromToken();
        if (!verificationId) {
          globalSnackbarVar({
            show: true,
            message: 'No se pudo obtener el id de verificación',
            severity: 'error',
          });
          return;
        }
      }

      const { signedDocs, publicKeyBase64 } = await signDocumentsWithEfirma({
        docs: filteresPendingDocs,
        password,
        privateKey,
        publicKey,
      });

      const signDocsRes = await sifeEndpoints.signDocsWithSatCryptographic.post(
        { signedDocs, efirmaPublicCert: publicKeyBase64, verificationId },
      );

      if (signDocsRes.status === 201) {
        // get documents signed from SIFE
        const { docsSigned } = signDocsRes.data.data;

        // call mutation to sincronize documents signed
        const docsToConfirm = documents
          .filter((doc) =>
            docsSigned.find((docSigned) => docSigned.docId === doc.id),
          )
          .map((doc) => ({ documentId: doc.id, type: doc.type }));

        await confirmDocumentSign({
          variables: {
            input: docsToConfirm,
          },
        });

        openFullScreenLottieAnimation(
          isMobile ? MOBILE_ANIMATION : DESKTOP_ANIMATION,
        );
        onSign();
      } else {
        const errors = signDocsRes.data.errors as any[];
        const errorTypes = errors.map((error) => error.type);

        let message = 'Ocurrió un error al firmar el documento(s)';
        if (errorTypes.includes('EFIRMA_CERT_EXPIRED')) {
          message = 'El certificado de eFirma ha expirado';
        } else if (errorTypes.includes('EFIRMA_CERT_REVOKED')) {
          message = 'El certificado de eFirma ha sido revocado';
        } else if (
          errorTypes.includes('EFIRMA_CERT_UNSUPPORTED') ||
          errorTypes.includes('EFIRMA_CERT_ISSUER_UNSUPPORTED')
        ) {
          message = 'El certificado de eFirma no es soportado';
        } else if (errorTypes.includes('EFIRMA_CERT_NOT_VALID')) {
          message = 'El certificado de eFirma no es válido';
        }
        globalSnackbarVar({ show: true, message, severity: 'error' });
      }
    } catch (error) {
      if (error.message === 'Wrong Password') {
        onIncorrectPassword();
      } else {
        globalSnackbarVar({
          show: true,
          message: 'Ocurrió un error al firmar el documento(s)',
          severity: 'error',
        });
      }
    } finally {
      setDocumentSignDialogVar({ signing: false });
    }
  };

  return {
    handleSignDocuments,
  };
};
