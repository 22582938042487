import { useReactiveVar } from '@apollo/client';
import { Stack, DialogContent, DialogActions } from '@mui/material';
import { Button } from '../../../../newComponents/Button';
import { Typography } from '../../../../newComponents/Typography';
import { IDBackIcon } from '../IdentityVerificationDialogAssets/IDBackIcon';
import { Alert } from '../../../../newComponents/Alert';
import { AlertTitle } from '../../../../newComponents/AlertTitle';
import { Icon } from '../../../../components/Icons/Icons';
import { useScreenSize } from '../../../../Hooks';
import { CameraNative } from '../../../NativeCamera';
import { useVerifyBackId } from '../IdentityVerificationDialogHooks/useVerifyBackId';
import {
  identityVerificationDialogVar,
  setIdentityVerificationDialogVar,
} from '../IdentityVerificationDialog.vars';

export function CaptureIDBack() {
  const { errorMessage, handleVerifyBackId } = useVerifyBackId();
  const identityVerificationDialog = useReactiveVar(
    identityVerificationDialogVar,
  );
  const { selectedIDType } = identityVerificationDialog;
  const { isMobile } = useScreenSize();
  const facingMode = 'environment';

  const handleBack = () => {
    if (!selectedIDType) return;
    const workflow = identityVerificationDialog[selectedIDType];
    const { currentStep } = workflow;

    setIdentityVerificationDialogVar({
      [selectedIDType]: {
        ...workflow,
        currentStep: currentStep - 1,
      },
    });
  };

  return (
    <>
      <DialogContent dividers sx={{ borderBottom: 'none' }}>
        <Stack
          maxWidth="800px"
          minHeight={isMobile ? '100%' : '65vh'}
          alignItems="center"
          justifyContent="center"
          margin="0 auto"
          gap={3}
        >
          <IDBackIcon height={80} />
          <Stack textAlign="center" alignItems="center" gap={2}>
            <Typography variant="h3">Captura el reverso de tu INE</Typography>
            <Typography variant="body1" color="grey" maxWidth="400px">
              Asegúrate de que tus datos sean visibles.
            </Typography>
          </Stack>
          {!isMobile && (
            <Stack direction="row" gap={2}>
              <Button
                variant="outlined"
                color="default"
                size="large"
                onClick={handleBack}
              >
                Atras
              </Button>
              <CameraNative
                onCapture={handleVerifyBackId}
                facingMode={facingMode}
              >
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  component="span"
                >
                  Tomar foto
                </Button>
              </CameraNative>
            </Stack>
          )}
          {errorMessage !== '' && (
            <Stack width="100%">
              <Alert icon={<Icon icon="information_line" />} severity="error">
                <AlertTitle>Vuelve a intentarlo</AlertTitle>
                {errorMessage}
              </Alert>
            </Stack>
          )}
        </Stack>
      </DialogContent>
      <DialogActions sx={{ padding: '16px 24px' }}>
        {isMobile ? (
          <Stack gap={2} width="100%">
            <Button
              variant="outlined"
              color="default"
              onClick={handleBack}
              fullWidth
            >
              Atras
            </Button>
            <CameraNative
              onCapture={handleVerifyBackId}
              facingMode={facingMode}
            >
              <Button
                variant="contained"
                color="primary"
                component="span"
                fullWidth
              >
                Tomar foto
              </Button>
            </CameraNative>
          </Stack>
        ) : (
          <Stack height="40px"></Stack>
        )}
      </DialogActions>
    </>
  );
}
