//@ts-check
import React, { useState } from 'react';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
} from '@mui/material';
import { useTheme } from '@mui/system';
import { Icon } from '../../../../components/Icons/Icons';
import { Typography } from '../../../../newComponents/Typography/Typography';
import { Dialog } from '../../../../components/Dialogs/Dialog';
import { Tabs } from '../../../../newComponents/Tabs/Tabs';
import { Tab } from '../../../../newComponents/Tab/Tab';
import { DataGrid } from '../../../../newComponents/DataGrid';
import { uuidv4 } from '../../../../utils/utils';
import { Button } from '../../../../newComponents/Button';
import { errorColumns, errorColumnsFromServer } from './PdfDocuments.constants';
import { buildCsvFile, downloadCsvFile } from './PdfDocuments.utils';
import { globalSnackbarVar } from '../../../../cache.reactiveVars';

/**
 * @typedef {import('../../../../theme').CustomTheme} CustomTheme
 */

/** @param {import('./ErrorsTable.types').ErrorTableProps} props */
export const ErrorsTable = ({
  open,
  onClose,
  onContinue,
  files,
  errorsFromServer,
  maxWidth = 'xs',
  resultData,
}) => {
  const [tabValue, setTabValue] = useState('errors');
  const [perPage, setPerPage] = useState(25);

  /** @type {CustomTheme} */
  const theme = useTheme();
  const palette = theme.newPalette;

  const handleChangeTab = (e, newValue) => {
    setTabValue(newValue);
  };

  const handlePerPage = (size) => {
    setPerPage(size);
  };

  const getHideFooter = () => {
    if (tabValue === 'ready') {
      return files?.acceptedFiles?.length < 25;
    }
    return files?.rejectedFiles?.length < 25;
  };

  const getCsvWithErrors = () => {
    const dataToDownload = resultData?.csvList ?? [];
    if (!dataToDownload?.length) {
      globalSnackbarVar({
        show: true,
        message: 'No hay información para descargar',
        severity: 'warning',
        duration: 5000,
      });
      return;
    }
    const translationMap = {
      names: 'Nombre',
      lastNameP: 'Apellido paterno',
      lastNameM: 'Apellido materno',
      rfc: 'RFC',
      curp: 'CURP',
      filename: 'Nombre archivo',
      message: 'Mensaje de error',
    };

    const csv = buildCsvFile(dataToDownload, translationMap);
    downloadCsvFile(csv, `list-de-errores-${JSON.stringify(new Date())}`);
  };

  return (
    <Dialog
      slideMode
      fullWidth
      open={open}
      maxWidth={maxWidth}
      onClose={() => {}}
      showCloseButton={false}
    >
      <DialogTitle display="flex" component="div" alignItems="center" gap={2}>
        <IconButton onClick={() => onClose()}>
          <Icon fill={palette.text.secondary} icon="close_line" height="20px" />
        </IconButton>
        <Typography variant="h6">Enviar documentos PDF</Typography>
      </DialogTitle>
      <DialogContent
        dividers
        sx={{ py: 3, px: '20px', display: 'flex', flexDirection: 'column' }}
      >
        <Stack flexGrow={1}>
          <Stack flexDirection="row" gap={2} alignItems="center">
            <Icon icon="error_warning_line" />
            <Typography variant="subtitle2">Errores encontrados</Typography>
          </Stack>
          {errorsFromServer && (
            <Stack
              component="ul"
              px={3}
              pt={2}
              sx={{ color: palette.grey.grey500 }}
            >
              <li>
                <Typography
                  variant="caption"
                  component="span"
                  color={palette.success.main}
                >
                  Se enviaron exitosamente {resultData?.successCreated ?? 0}{' '}
                  documentos.
                </Typography>
              </li>
              <li>
                <Typography
                  variant="caption"
                  color={palette.error.main}
                  component="span"
                >
                  Se encontraron errores en {resultData?.csvList?.length ?? 0}{' '}
                  documentos de los {resultData?.totalDocs ?? 0} que se
                  intentaron crear. Carga de nuevo los documentos a los usuarios
                  con error {resultData?.csvList?.length ?? 0}.
                </Typography>
              </li>
            </Stack>
          )}

          <Stack flexDirection="row" gap={2} alignItems="center" mt={2}>
            <Typography variant="caption" color={palette.text.secondary}>
              {!errorsFromServer
                ? 'Se encontraron los siguientes errores al cargar tus archivos'
                : 'Da click en el botón para descargar el listado de documentos y usuarios con error. Recuerda que puedes copiar y pegar la lista de RFCs en la barra de búsqueda para asignar.'}
            </Typography>
          </Stack>
          <Stack mt={2}>
            {!errorsFromServer && (
              <Tabs
                value={tabValue}
                onChange={handleChangeTab}
                color={tabValue === 'ready' ? 'primary' : 'error'}
              >
                {files?.acceptedFiles?.length > 0 && (
                  <Tab
                    value="ready"
                    label="Listos para configurar"
                    iconPosition="start"
                    icon={
                      <DisplayFileNumber
                        number={files?.acceptedFiles?.length || 0}
                        color={palette.primary.dark}
                        bgColor={palette.primary.transparent16}
                      />
                    }
                  />
                )}
                <Tab
                  value="errors"
                  label="Errores"
                  iconPosition="start"
                  icon={
                    <DisplayFileNumber
                      number={files?.rejectedFiles?.length || 0}
                      color={palette.error.dark}
                      bgColor={palette.error.transparent16}
                    />
                  }
                />
              </Tabs>
            )}
            <Stack height="600px">
              <DataGrid
                getRowId={() => uuidv4()}
                columns={
                  errorsFromServer
                    ? errorColumnsFromServer({ palette, getCsvWithErrors })
                    : errorColumns
                }
                rows={
                  tabValue === 'ready'
                    ? [...(files?.acceptedFiles || [])]
                    : [...(files?.rejectedFiles || [])]
                }
                checkboxSelection={false}
                isRowSelectable={() => false}
                rowsPerPageOptions={[25]}
                onPageSizeChange={(newPageSize) => handlePerPage(newPageSize)}
                pageSize={perPage}
                hideFooter={getHideFooter()}
                sx={{
                  '& .MuiDataGrid-row': {
                    display: 'flex',
                    alignItems: 'center',
                    minHeight: '76px !important',
                    maxHeight: '76px !important',
                  },
                }}
                disableColumnFilter
                disableColumnMenu
              />
            </Stack>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack p={2} flexDirection="row" gap={2}>
          {!errorsFromServer && (
            <>
              <Button
                variant="outlined"
                color="default"
                size="large"
                onClick={() => onClose()}
              >
                Cancelar
              </Button>
              <Button
                variant="contained"
                color="primary"
                size="large"
                disabled={!files?.acceptedFiles?.length}
                onClick={() => {
                  onContinue(files.acceptedFiles);
                  onClose();
                }}
              >
                Continuar e ignorar errores
              </Button>
            </>
          )}
          {errorsFromServer && (
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={() => onClose()}
            >
              Aceptar
            </Button>
          )}
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

const DisplayFileNumber = ({ number, color, bgColor }) => {
  return (
    <Stack
      bgcolor={bgColor}
      color={color}
      padding="2px 8px 2px 8px"
      alignItems="center"
      justifyContent="center"
      borderRadius="6px"
      height="24px"
      minWidth="24px"
      mr={1}
    >
      {number}
    </Stack>
  );
};
