import { endOfMonth, startOfMonth, sub } from 'date-fns';

export const rfcRegex =
  /^(\w{3}|\w{4})\d{2}(0+[1-9]|1[012])([12][0-9]|3[01]|0+[1-9])\w{3}$/;

export const emailRegex =
  /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/;

export const mimeTypes = {
  spreadsheet:
    '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/wps-office.xlsx',
  zip: '.zip,.rar,.7zip',
  xml: 'text/xml',
  image: ['.jpg', '.jpeg', '.png'],
  pdf: '.pdf',
  cer: '.cer',
  key: '.key',
};

export const supportWhatsappLink =
  'https://api.whatsapp.com/send/?phone=5218180781204&text&app_absent=0';

export const letterConstants = {
  treatmentLetter: 'CARTA_TRATAMIENTO_DATOS_PERSONALES',
  workingLetter: 'CARTA_LABORAL',
  createCompanyLetter: 'CARTA_RESPONSABILIDAD_CREACION_EMPRESA',
  adminLetter: 'CARTA_ADMIN_CENTRO_TRABAJO',
  genericDocument: 'GENERIC_DOCUMENT',
};

export const valueToLockNewReceipts = 2;
export const valueToLockDocumentsHistory = 1;
export const zdSupportChat =
  'https://static.zdassets.com/ekr/snippet.js?key=e69a5975-7e92-4668-a45b-a7e8d47d5d3e';

export const snackbarSupportMessage =
  'Ocurrio un problema con la petición. Vuelva a intentar o contacte a soporte';

export const dateRanges = {
  lastThreeMonths: {
    start: startOfMonth(sub(startOfMonth(new Date()), { months: 2 })),
    end: endOfMonth(new Date()),
    title: '3 meses',
    key: 'lastThreeMonths',
  },
  lastSixMonths: {
    start: startOfMonth(sub(startOfMonth(new Date()), { months: 5 })),
    end: endOfMonth(new Date()),
    title: '6 meses',
    key: 'lastSixMonths',
  },
  lastTwelveMonths: {
    start: startOfMonth(sub(startOfMonth(new Date()), { months: 11 })),
    end: endOfMonth(new Date()),
    title: '12 meses',
    key: 'lastTwelveMonths',
  },
  historic: {
    start: null,
    end: null,
    title: 'Histórico',
    key: 'historic',
  },
};

export const rangeDatesTitles = () => {
  return Object.keys(dateRanges).map((key) => {
    return {
      title: dateRanges[key].title,
      key,
    };
  });
};
